<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Add New Session</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-autocomplete
                v-model="form_data.vendor_id"
                :items="vendors"
                item-text="name"
                item-value="vendor_id"
                label="Select Vendor"
                :error-messages="form_error.vendor_id"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-autocomplete
                v-model="form_data.attendee_keys"
                :items="vendor_attendee"
                item-text="full_name"
                item-value="attendee_id"
                label="Select Attendee"
                :error-messages="form_error.attendee_keys"
                outlined
                dense
                multiple
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.title"
                :error-messages="form_error.title"
                label="Seesion title"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-textarea
                name="input-5-1"
                label="Description"
                v-model="form_data.description"
                rows="3"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-datetime-picker
                label="Start Datetime"
                v-model="form_data.start_time"
                :error-messages="form_error.start_time"
                :text-field-props="textFieldProps"
                :date-picker-props="dateProps"
                :time-picker-props="timeProps"
                outlined
              >
                <template slot="actions" slot-scope="{ parent }">
                  <v-btn color="error lighten-1" @click="parent.clearHandler">Clear</v-btn>
                  <v-btn color="success darken-1" @click="parent.okHandler">Done</v-btn>
                </template>
                <div slot="dateIcon" color="primary" dark>
                  <v-icon>mdi-calendar</v-icon>
                </div>
                <div slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </div>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-datetime-picker
                label="End Datetime"
                v-model="form_data.end_time"
                :text-field-props="textFieldProps"
                :error-messages="form_error.end_time"
                :date-picker-props="dateProps"
                :time-picker-props="timeProps"
              >
                <template slot="actions" slot-scope="{ parent }">
                  <v-btn color="error lighten-1" @click="parent.clearHandler">Clear</v-btn>
                  <v-btn color="success darken-1" @click="parent.okHandler">Done</v-btn>
                </template>
                <div slot="dateIcon" color="primary" dark>
                  <v-icon>mdi-calendar</v-icon>
                </div>
                <div slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </div>
              </v-datetime-picker>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-switch
                v-model="form_data.is_live_meeting"
                label="Live Meeting"
                color="success"
                hide-details
                class="mt-2 mb-4"
              ></v-switch>
            </v-col>
          </v-row> -->
          <v-row>
            <!-- <v-col cols="12" md="6" sm="12" class="py-0">
              <v-color-picker v-model="color"></v-color-picker>
            </v-col>-->
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.meeting_id_url"
                :error-messages="form_error.meeting_id_url"
                label="Video Url"
                outlined
                dense
              ></v-text-field>
              <!-- <v-file-input
                v-model="form_data.pre_recorded_video"
                :error-messages="form_error.pre_recorded_video"
                accept="video/*"
                placeholder="Pre Recorded Video"
                prepend-icon="mdi-file-video"
                outlined
                dense
              ></v-file-input> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-file-input
                v-model="form_data.slide_file"
                label="Presentation slides"
                accept=".pptx,.ppt,.pdf,.pot"
                prepend-icon=""
                outlined
                dense
                :error="!!form_error.slide_file"
                :error-messages="form_error.slide_file"
              />
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.pre_recorded_video"
                :error-messages="form_error.pre_recorded_video"
                label="Video URL"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row> -->
          <v-btn class="mr-4" color="secondary" @click="addSession" :loading="isSavingSession">submit</v-btn>
        </form>
      </v-card-text>
    </v-card>
    <v-btn small color="secondary" fixed fab bottom left to="/session">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
 
import { mapMutations } from "vuex";
export default {
  name: "AddSession",
  data() {
    return {
      form_data: {
        start_time: new Date(),
        end_time: new Date(),
        is_live_meeting:false,
        slide_file: null
      },
      form_error: {},
      vendors: [],
      vendor_attendee:[],
      loading: false,
      data: {},
      textFieldProps: {
        outlined: "",
        dense: "",
      },
      dateProps: {
        headerColor: "primary",
        color: "secondary",
        class: "rounded-0",
      },
      timeProps: {
        headerColor: "primary",
        color: "secondary",
        class: "rounded-0",
      },
      isSavingSession: false,
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    addSession() {
      this.loading = true;
      this.isSavingSession = true;

      let serverData = {
        ...this._data.form_data
      }; 

      let formData = new FormData();

      for (let key in serverData) {
        formData.append(key, serverData[key] ?? "");
      }

      if (formData.has("meeting_id_url")) {
        formData.set("pre_recorded_video", formData.get("meeting_id_url"))
      }

      this.$axios.post(`admin/session/add`, formData)
        .then((response) => {
          let resData = response.data;
          this.form_error = {};

          if (resData.status) {
            this.setAlert({
              show: true,
              variant: "success",
              message: resData.message,
              dismissCountDown: 5000,
            });

            this.user_data = resData.user_data;
            
            this.$router.push("/session");
          } else {
            let errors = resData.error;

            for (let key in errors) {
              this.form_error[errors[key].field] = errors[key].message;
            }
          }
        })
        .finally(() => {
          this.isSavingSession = false;
          this.loading = false;
        });
    },
    getVendorList() {
      let _self = this;
      this.$axios.get("/admin/vendor/list?pagination=false")
        .then((response) => {
          _self.vendors = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    getRepList(){
      let _self = this;
      this.$axios.get("/admin/get_speaker")
      .then((response) => {
        _self.vendor_attendee = response.data.data;
        this.loading = false;
      })
      .catch(function () {
        this.loading = false;
      });
    }
  },
  mounted: function () {
    this.loading = "secondary";
    this.getVendorList();
    this.getRepList()
  },
};
</script>